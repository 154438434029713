<template>
  <div>
    <HeaderBar />
    <div class="logo-header">
      <router-link to="/">
        <img src="@/assets/images/logo.png" alt="logo">
      </router-link>
      <span v-if="passwordShow">{{ passwordShowCtn }}</span>
      <div v-if="payment || orderConfirm" class="order-process-bar">
        <div>我的购物车<div class="arrow-right" /></div>
        <div :class="{'orderConfirm':orderConfirm}"><div class="arrow-left" />确认订单信息<div class="arrow-right" /></div>
        <!-- <div :class="{'paymentbar':payment}"><div class="arrow-left" />确认支付信息<div class="arrow-right" /></div> -->
        <div :class="{'paymentbar':payment}"><div class="arrow-left" />成功提交订单</div>
      </div>
    </div>
    <Container class="fullScreem">
      <nuxt />
    </Container>
    <FloatingServiceMenu />
    <ElFooter />
    <!-- <SectionBar /> -->
  </div>
</template>


<script>
import FloatingServiceMenu from "@/components/FloatingServiceMenu.vue";
import HeaderBar from "@/components/share/headerBar/HeaderBar.vue";
import Footer from "@/components/share/Footer.vue";
// import SectionBar from "@/components/share/SectionBar.vue";
// import "@/static/fonts/iconfont.css";
import { Container } from "element-ui";
export default {
  head() {
    return {
      meta: [{ name: "renderer", content: "webkit" }]
    };
  },
  layout: "loginAndRegister",
  components: {
    Container,
    HeaderBar,
    ElFooter: Footer,
    FloatingServiceMenu
    // SectionBar
  },
  data() {
    return {
      passwordShow: false,
      passwordShowCtn: "",
      payment: false,
      success: false,
      orderConfirm: false
    };
  },
  watch: {
    $route() {
      this.$router.go(0);
    }
  },
  mounted() {
    //根据url判定logo-bar的显示
    console.log(window.location.pathname, "pathname"); //eslint-disable-line
    let tmp = window.location.pathname;
    if (~tmp.indexOf("success")) {
      this.success = true;
    } else if (~tmp.indexOf("orderConfirm")) {
      this.orderConfirm = true;
    } else if (~tmp.indexOf("payment")) {
      this.payment = true;
    } else if (~tmp.indexOf("password/modifyPayment")) {
      this.passwordShow = true;
      this.passwordShowCtn = "修改支付密码";
    } else if (~tmp.indexOf("password/findPayment")) {
      this.passwordShow = true;
      this.passwordShowCtn = "找回支付密码";
    } else if (~tmp.indexOf("password/find")) {
      this.passwordShow = true;
      this.passwordShowCtn = "找回密码";
    } else if (~tmp.indexOf("password/modify")) {
      this.passwordShow = true;
      this.passwordShowCtn = "修改密码";
    } else if (~tmp.indexOf("activeOrderConfirm")) {
      this.orderConfirm = true;
    }
  }
};
</script>

<style scoped>
.fullScreem {
  width: 100%;
  margin: 0 auto;
}
.orderConfirm {
  background-color: #d70010;
  color: white;
}

.orderConfirm .arrow-right {
  border-left: 10px solid #d70010;
}

.orderConfirm .arrow-left {
  border: 15px solid #d70010;
  border-left: 10px solid white;
}

.paymentbar {
  background-color: #d70010;
  color: white;
}

.paymentbar .arrow-right {
  border-left: 10px solid #d70010;
}

.paymentbar .arrow-left {
  border: 15px solid #d70010;
  border-left: 10px solid white;
}

.successbar {
  background-color: #d70010;
  color: white;
}

.successbar .arrow-right {
  border-left: 10px solid #d70010;
}

.successbar .arrow-left {
  border: 15px solid #d70010;
  border-left: 10px solid white;
}
</style>


<style>
.clearfix::after {
  display: block;
  content: "";
  clear: both;
}
* {
  margin: 0;
  padding: 0;
  font-family: "Microsoft YaHei";
}

a {
  text-decoration: none;
}

.hot {
  color: red;
}

ul {
  list-style: none;
  padding: 0;
}

.content {
  background-color: #f2f2f2;
}

.icon {
  width: 30px;
  height: 30px;
  display: inline-block;
  position: absolute;
}

.logo-header {
  padding-top: 40px;
  height: 170px;
  width: 1200px;
  margin: 0 auto;
  /* border-bottom: 2px solid #e4e4e4; */
  position: relative;
}

.order-process-bar {
  position: absolute;
  right: 0px;
  bottom: 15px;
}

.order-process-bar > div {
  float: left;
  height: 30px;
  line-height: 30px;
  background-color: #e6e6e6;
  text-align: center;
  font-size: 16px;
  width: 185px;
  margin-left: -10px;
}

.order-process-bar > div:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.order-process-bar > div:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.arrow-left {
  float: left;
  width: 0px;
  border: 15px solid #e6e6e6;
  border-left: 10px solid white;
}

.arrow-right {
  float: right;
  width: 0px;
  border: 15px solid white;
  border-left: 10px solid #e6e6e6;
}

/* .logo-header > img {
  margin-top: 40px;
} */

.logo-header > span {
  display: inline-block;
  height: 30px;
  border-left: 1px solid #c1c1c1;
  font-size: 24px;
  color: #adadad;
  padding-left: 25px;
  vertical-align: 50px;
  margin-left: 15px;
}
</style>
