<template>
  <div>
    <nuxt />
    <FloatingServiceMenu />
  </div>
</template>

<script>
import FloatingServiceMenu from "@/components/FloatingServiceMenu.vue";
export default {
  components: {
    FloatingServiceMenu
  },
  head() {
    return {
      meta: [{ name: "renderer", content: "webkit" }]
    };
  }
};
</script>
