<template>
  <div>
    <!-- 浮动菜单栏 -->
    <div class="float-menu">
      <!-- 租赁热线图标 -->
      <div class="menu-item" @mouseenter="(e) => updateInfoPosition('phone', e)" @mouseleave="startHideTimer('phone')">
        <img src="@/assets/icon/phone.png" alt="phone">
      </div>

      <!-- 企业微信图标 -->
      <div
        class="menu-item" @mouseenter="(e) => updateInfoPosition('wechat', e)"
        @mouseleave="startHideTimer('wechat')"
      >
        <img src="@/assets/icon/vxicon.png" alt="wechat">
      </div>

      <!-- 留言图标 -->
      <div class="menu-item" @click="handleMessage">
        <img src="@/assets/icon/message.png" alt="message">
      </div>

      <!-- 回到顶部图标 -->
      <div class="menu-item" @click="scrollToTop">
        <img src="@/assets/icon/top.png" alt="top">
      </div>
    </div>

    <!-- 租赁热线信息框 -->
    <transition name="fade">
      <div v-if="showPhoneInfo" class="info-box phone-info-box" :style="phoneInfoPosition">
        <span class="phone-number">服务热线：400-051-2525 </span>
      </div>
    </transition>

    <!-- 企业微信信息框 -->
    <transition name="fade">
      <div v-if="showWechatInfo" class="info-box wechat-info-box" :style="wechatInfoPosition">
        <div class="wechatInfo-box">
          <img src="@/assets/icon/vx.png" width="122" height="122" alt="QR Code">
          <p>添加我的企业微信</p>
        </div>
      </div>
    </transition>

    <div v-if="globalContactVisible" class="contact-global">
      <div class="contact-global__ctn">
        <img src="@/assets/icon/vx.png" alt="">
        <p>微信扫码添加客服微信<br>获取车辆详细配置与报价等</p>
        <img class="close-btn" src="@/assets/icon/close_circle.png" alt="" @click="globalContactVisible = false">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPhoneInfo: false, // 是否显示租赁热线信息框
      showWechatInfo: false, // 是否显示企业微信信息框
      phoneInfoPosition: { top: "0px", left: "0px" }, // 租赁热线信息框的位置
      wechatInfoPosition: { top: "0px", left: "0px" }, // 企业微信信息框的位置
      hideTimer: null, // 控制隐藏的定时器
      globalContactVisible: false
    };
  },
  mounted() {
    // 添加全局点击事件监听，点击菜单外的区域时隐藏信息框
    document.addEventListener("click", this.handleClickOutside);

    const waitForDisplay = !sessionStorage.getItem("global_contact_show");
    const notLogin = !this.$store.state.user.isLogin;
    if (waitForDisplay && notLogin) {
      setTimeout(() => {
        sessionStorage.setItem("global_contact_show", 1);
        this.globalContactVisible = true;
      }, 60000);
    }
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
    this.clearHideTimer();
  },
  methods: {
    // 更新信息框位置的方法
    updateInfoPosition(type, event) {
      this.clearHideTimer(); // 清除隐藏定时器，防止闪烁

      const menuItem = event.currentTarget;
      const rect = menuItem.getBoundingClientRect();

      if (type === "phone") {
        this.phoneInfoPosition = {
          top: `${rect.top}px`,
          left: `${rect.left - 279}px` // 移动到图标左侧
        };
        this.showPhoneInfo = true;
        this.showWechatInfo = false;
      } else if (type === "wechat") {
        this.wechatInfoPosition = {
          top: `${rect.top}px`,
          left: `${rect.left - 167}px`
        };
        this.showWechatInfo = true;
        this.showPhoneInfo = false;
      }
    },

    // 延迟隐藏信息框的方法
    startHideTimer(type) {
      this.hideTimer = setTimeout(() => {
        if (type === "phone") this.showPhoneInfo = false;
        if (type === "wechat") this.showWechatInfo = false;
      }, 300);
    },

    // 清除隐藏定时器
    clearHideTimer() {
      if (this.hideTimer) {
        clearTimeout(this.hideTimer);
        this.hideTimer = null;
      }
    },

    // 处理点击留言图标的方法
    handleMessage() {
      window.open(
        "https://work.weixin.qq.com/kfid/kfca1911450e5b2b013",
        "_blank"
      );
    },

    // 平滑滚动回到顶部
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    // 点击菜单区域外时隐藏所有信息框
    handleClickOutside(event) {
      const isClickInside =
        event.target.closest(".float-menu") ||
        event.target.closest(".info-box");
      if (!isClickInside) {
        this.showPhoneInfo = false;
        this.showWechatInfo = false;
      }
    }
  }
};
</script>

<style scoped>
.float-menu {
  position: fixed;
  right: 20px;
  bottom: 50px;
  background-color: #d7d7d7;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 999;
}

.menu-item {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  border-bottom: 1px solid #fff;
}

.menu-item:last-child {
  border-bottom: none;
}

.menu-item:hover {
  background-color: #c52922;
}

.menu-item:nth-child(1):hover {
  background-color: #c52922;
  border-radius: 8px 8px 0 0;
}

.menu-item:nth-child(4):hover {
  background-color: #c52922;
  border-radius: 0 0 8px 8px;
}

.menu-item img {
  width: 48px;
  height: 48px;
}

.info-box {
  position: fixed;
  padding: 15px;
  background: #c52922;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
  z-index: 999;
  color: #fff;
  pointer-events: none;
}

.phone-info-box {
  min-width: 220px;
  text-align: center;
}

.info-box span {
  font-weight: 800;
  font-size: 20px;
}

.info-box p {
  font-weight: 800;
  font-size: 16px;
  margin-top: 10px;
}

.wechatInfo-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contact-global {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.contact-global__ctn {
  background-color: white;
  border-radius: 15px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  padding-bottom: 20px;
  text-align: center;
  line-height: 1.3;
}

.contact-global__ctn p {
  margin-top: -10px;
}

.close-btn {
  width: 50px;
  position: absolute;
  bottom: -80px;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
</style>
