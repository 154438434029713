<template>
  <div>
    <nuxt />
    <FloatingServiceMenu />
  </div>
</template>


<script>
import FloatingServiceMenu from "@/components/FloatingServiceMenu.vue";
export default {
  head() {
    return {
      meta: [{ name: "renderer", content: "webkit" }]
    };
  },
  components: {
    FloatingServiceMenu
  },
  data() {
    return {};
  }
};
</script>
<style>
* {
  margin: 0;
  padding: 0;
  font-family: "Microsoft YaHei";
}

html {
  font-size: 20px;
  width: 100%;
}

a {
  text-decoration: none;
}

.hot {
  color: red;
}

ul {
  padding: 0;
}

.content {
  background-color: #f2f2f2;
}

.icon {
  width: 30px;
  height: 30px;
  display: inline-block;
  position: absolute;
}

.clearfix::after {
  display: block;
  content: "";
  clear: both;
}

.topAd {
  height: 150px;
  background: url("~assets/images/leaderApply/headerADadd.jpg");
  background-position-x: center;
  min-width: 1200px;
}

.ad-ctn {
  width: 1200px;
  margin: 0 auto;
}

.word-wrapper {
  padding-top: 45px;
  float: right;
}

.word-wrapper > .ad-item {
  display: inline-block;
  font-size: 18px;
  color: white;
  line-height: 1.5;
  vertical-align: top;
}

.word-wrapper > .ad-item:last-child {
  margin: 5px 46px 0px 40px;
}
</style>
