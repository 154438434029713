<template>
  <div style="background-color:white;" class="member_center">
    <HeaderBar />
    <LogoTitle />
    <Container>
      <div class="section-nav">
        <ul v-for="(item, index) in nav" :key="'nav'+index">
          <li v-for="(child, _index) in item" :key="'child'+_index"><a :class="(child.url && pathname === child.url) ? 'active': '' " :href="child.url">{{ child.title }}</a></li>
        </ul>
      </div>
      <nuxt />
    </Container>
    <FloatingServiceMenu />
    <ElFooter />
    <!-- <SectionBar /> -->
  </div>
</template>

<script>
import FloatingServiceMenu from "@/components/FloatingServiceMenu.vue";
import HeaderBar from "@/components/share/headerBar/HeaderBar.vue";
import LogoTitle from "@/components/share/headerBar/LogoTitle.vue";
import Footer from "@/components/share/Footer.vue";
// import SectionBar from "@/components/share/SectionBar.vue";
import { Container } from "element-ui";

export default {
  head() {
    return {
      meta: [{ name: "renderer", content: "webkit" }]
    };
  },
  components: {
    HeaderBar,
    LogoTitle,
    ElFooter: Footer,
    Container,
    FloatingServiceMenu
    // SectionBar
  },
  data() {
    return {
      pathname: "/memberCenter",
      nav: [
        [
          { title: "订单管理" },
          { title: "我的订单", url: "/myOrder" },
          { title: "我的活动", url: "/sellOrder" },
          { title: "旧版本订单", url: "/memberCenter/historyOrder" }
        ],
        [
          { title: "资产管理" },
          { title: "兑账单管理", url: "/memberCenter/property/exchangeBill" },
          { title: "我的额度", url: "/memberCenter/property/balance" },
          { title: "阿母积分", url: "/memberCenter/property/myIMB" },
          // { title: "金分榜单", url: "/memberCenter/property/pointsManagement" },
          { title: "优惠券", url: "/memberCenter/property/coupon" }
        ],
        [
          { title: "账户管理" },
          { title: "发票管理", url: "/memberCenter/property/invoice" },
          { title: "地址管理", url: "/memberCenter/address" },
          { title: "密码管理", url: "	/password/modify" },
          { title: "个人资料", url: "/memberCenter/personalData" }
        ]
      ]
      // nav: [
      //   [
      //     { title: "账户设置" },
      //     { title: "我的阿母", url: "/memberCenter" },
      //     { title: "个人资料", url: "/memberCenter/personalData" },
      //     { title: "地址管理", url: "/memberCenter/address" },
      //     { title: "修改登陆密码", url: "	/password/modify" },
      //     { title: "重置支付密码", url: "	/password/findPayment" }
      //   ],
      //   [
      //     { title: "我的资产" },
      //     { title: "金分管理", url: "/memberCenter/property/pointsManagement" },

      //     {
      //       title: "我的信用分",
      //       url: "/memberCenter/property/credit/creditShort"
      //     },
      //     {
      //       title: "我的长期信用分",
      //       url: "/memberCenter/property/credit/creditLong"
      //     },
      //     { title: "我的阿母积分", url: "/memberCenter/property/myIMB" },
      //     { title: "我的店铺余额", url: "/memberCenter/property/balance" },
      //     { title: "我的优惠券", url: "/memberCenter/property/discountCoupon" },
      //     { title: "发票管理", url: "/memberCenter/property/invoice" }
      //   ],
      //   [
      //     { title: "交易中心" },
      //     { title: "我的订单", url: "/memberCenter/myOrder" }
      //   ]
      // ]
    };
  },
  mounted() {
    this.pathname = window.location.pathname;
  }
};
</script>
<style>
.clearfix::after {
  display: block;
  content: "";
  clear: both;
}
* {
  margin: 0;
  padding: 0;
  font-family: "Microsoft YaHei";
}

a {
  text-decoration: none;
}

.hot {
  color: red;
}

ul {
  list-style: none;
  padding: 0;
}

.content {
  background-color: #f2f2f2;
}

.icon {
  width: 30px;
  height: 30px;
  display: inline-block;
  position: absolute;
}

.logo-header {
  height: 170px;
  width: 1200px;
  margin: 0 auto;
}

.logo-header > img {
  margin-top: 40px;
}

.logo-header > span {
  display: inline-block;
  height: 30px;
  border-left: 1px solid #c1c1c1;
  font-size: 24px;
  color: #adadad;
  padding-left: 25px;
  vertical-align: 50px;
  margin-left: 15px;
}

.member_center .el-container {
  width: 1200px;
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 130px;
}

.section-nav {
  /* padding-left: 28px; */
  font-size: 16px;
}

.section-nav a {
  color: black;
}

.section-nav a.active {
  color: #d7000f;
}

.section-nav > ul > li {
  margin-bottom: 12px;
  width: 160px;
}

.section-nav > ul > li:first-child {
  font-weight: bold;
  margin-bottom: 16px;
}

.section-nav > ul > li:last-child {
  margin-bottom: 28px;
}

.add-row {
  background-color: oldlace !important;
}

.minus-row {
  background-color: #f0f9eb !important;
}
</style>
